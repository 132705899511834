class ScrollingText {
	constructor(container) {
		this.container = container
		if(!this.container) return
		this.speedMultiplier = (this.container.classList.contains('marquee_banner')) ? 40 : 100

		this.windowWidth = window.innerWidth
		this.desiredWidth = this.windowWidth * 2

		this.wordsP = this.container.querySelector('p')
		this.words = this.wordsP.innerHTML,
		this.width = this.wordsP.getBoundingClientRect().width
		this.width = this.wordsP.offsetWidth
		this.speed = this.width / this.speedMultiplier

		this.container.style.setProperty('--animationDistance', '-' + this.width + 'px')
		this.container.style.setProperty('--animationSpeed', this.speed + 's')

		this.init()
	}

	calculate() {
		this.container.style.setProperty('--animationDistance', '-' + this.width + 'px')
		this.container.style.setProperty('--animationSpeed', this.speed + 's')
		let width = this.width,
		words = this.words,
		count = 0
		while(width < this.windowWidth) {
			width += this.width
			words += this.words
			count++
		}
		words += this.words

		this.wordsP.innerHTML = words
	}

	init() {
		this.width = this.wordsP.getBoundingClientRect().width
		setTimeout( () => {
			this.calculate()
		}, 200)
	}
}


let marquee_banner
const scrolling_text = document.querySelector('.marquee_banner')
if(scrolling_text) {
	marquee_banner = new ScrollingText(scrolling_text)
}

