jQuery('.stories_carousel').slick({
	infinite: true,
	slidesToShow: 1,
	slidesToScroll: 1,
	dots: false,
	arrows: false,
	autoplay: false,
	autoplaySpeed: 6000,
	pauseOnHover: false,
	pauseOnFocus: false,
});

jQuery('.carousel-prev').on('click', function(){
	jQuery('.stories_carousel').slick('slickPrev');
})

jQuery('.carousel-next').on('click', function(){
	jQuery('.stories_carousel').slick('slickNext');
})