
function homeVideo() {
	const homeVideo = document.querySelector('.home .page_background video')
	if(homeVideo) {
		homeVideo.playbackRate = 1.5;
	}
}

window.addEventListener('load', homeVideo, { passive: false })

if(navigator.userAgent.indexOf("Chrome") != -1 || navigator.userAgent.indexOf("Firefox") != -1 || navigator.userAgent.indexOf("Safari") != -1) {
	jQuery('body')[0].classList.add('chrome');
}

if(navigator.userAgent.indexOf("Edge") != -1 || navigator.userAgent.match("LinkedInApp") || navigator.appName == 'Microsoft Internet Explorer' ||  !!(navigator.userAgent.match(/Trident/) || navigator.userAgent.match(/rv:11/))) {
	jQuery('body')[0].classList.remove('chrome');
}

jQuery(document).ready(function() {
	var acc = document.getElementsByClassName("mobile-dropdown");
	var i;
	for (i = 0; i < acc.length; i++) {
		acc[i].addEventListener("click", function() {
			this.classList.toggle("opened");
			var panel = this.nextElementSibling;
			var parent = jQuery(this).parents('.dropdown-menu.opened')[0];

			if (panel.style.maxHeight) {
				panel.style.maxHeight = null;
				panel.classList.toggle("opened");
			} else {
				panel.style.maxHeight = panel.scrollHeight + "px";
				panel.classList.toggle("opened");
			}

			if(parent) {	
				parent.style.maxHeight = parent.scrollHeight + panel.scrollHeight +  "px";
			}
		});
	};
});