function fixed() {
	let fixedArea = document.querySelector('.fixed')

	if(fixedArea) {
		fixedArea.classList.add('affix')
		let fixedHeight = fixedArea.getBoundingClientRect().height,
			fixedStart = document.querySelector('.fixed_start')
		
		if(fixedStart) fixedStart.style.height = fixedHeight + 'px'
	}
}

window.addEventListener("load", fixed, false)
window.addEventListener("resize", fixed, false)