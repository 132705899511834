class HorizontalCarousel {
	constructor() {

		this.container = document.querySelector('.product_carousel')
		this.controls = this.container.querySelector('.carousel_controls')
		this.carousel = this.container.querySelector('.scrollable')
		this.slides = this.carousel.querySelectorAll('.product')
		this.btnNext = this.controls.querySelector('.carousel_next')
		this.btnPrev = this.controls.querySelector('.carousel_prev')
		this.totalWidth = 0
		this.slideWidth = 0

		this.init()
	}

	

	scrollPrev() {
		this.carousel.scrollBy({
			top: 0,
			left: -this.slideWidth,
			behavior: 'smooth'
		})
	}

	scrollNext() {
		this.carousel.scrollBy({
			top: 0,
			left: this.slideWidth,
			behavior: 'smooth'
		})
	}

	onResize() {
		var that = this
		let resizeTimer
		clearTimeout(resizeTimer)
		resizeTimer = setTimeout(function() {
			that.setWidth()
		}, 500)
	}

	setWidth() {
		this.totalWidth = 0;
		let margin = (window.innerWidth > 992) ? 60 : 0
		this.slides.forEach( (slide) => {
			const slideWidth = slide.offsetWidth
			this.totalWidth += slideWidth + margin
			this.slideWidth = slideWidth + margin
		})
		this.totalWidth += (window.innerWidth > 992) ? 100 : 30
		this.container.style.setProperty("--width", this.totalWidth + 'px')
	}

	bindAll() {
		["onResize", "scrollPrev", "scrollNext"].forEach((fn) => (this[fn] = this[fn].bind(this)));
	}

	init() {
		this.bindAll()
		this.setWidth()
		window.addEventListener("resize", this.onResize)
		this.btnNext.addEventListener("mousedown", () => {
			this.scrollNext()
		})
		this.btnPrev.addEventListener("mousedown", () => {
			this.scrollPrev()
		})
	}
}

const carousel = document.querySelector('.product_carousel')
if(carousel) {
	const productCarousel = new HorizontalCarousel()
}
