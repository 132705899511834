function toggleNav(transition = true) {
	document.body.classList.toggle('noscroll')
	const toggle = document.querySelector('.toggle');
	if (toggle.classList.contains('closed')) {
		toggle.classList.remove('closed')
		toggle.classList.add('open')
		openNav()
	} else {
		toggle.classList.remove('open')
		toggle.classList.add('closed')
		closeNav()
	}
}

function openNav() {
	//document.getElementById("mobileOverlay").style.width = "calc(100% + 20px)";
	document.getElementById("mobileOverlay").classList.add('open')
	setTimeout(function () {
		var children = document.getElementsByClassName("mobile-nav");
		for (var i = 0, len = children.length | 0; i < len; i = i + 1 | 0) {
			children[i].style.opacity = 1;
		}

	}, 300);
}

function closeNav(transition = true) {
	var children = document.getElementsByClassName("mobile-nav");
	for (var i = 0, len = children.length | 0; i < len; i = i + 1 | 0) {
		children[i].style.opacity = 0;
	}
	if (transition == false) {
		document.getElementById("mobileOverlay").style.transition = "0s";
	}
	setTimeout(function () {
		//document.getElementById("mobileOverlay").style.width = "0%";
		document.getElementById("mobileOverlay").classList.remove('open')
		
	}, 200);
	setTimeout(function () {
		document.getElementById("mobileOverlay").style.transition = ".3s all linear";
	}, 300);
}

function mobileSubNav() {
	if (window.innerWidth < 992) {
		document.getElementById("overlayContent").style.left = "-100%"
		let childNav = this.parentElement.lastElementChild
		backBtn = document.createElement('li')

		childNav.style.opacity = 1;
		childNav.style.left = '100%';
		backBtn.innerHTML = 'Back'
		backBtn.setAttribute('id', 'back_btn')
		childNav.appendChild(backBtn)

		let childLinks = childNav.getElementsByTagName("li")
		for (var i = 0, len = childLinks.length | 0; i < len; i = i + 1 | 0) {
			childLinks[i].addEventListener('click', function () {
				if (this.id != 'back_btn') {
					closeNav(false)
				}
				document.getElementById("overlayContent").style.left = "0%"
				childNav.removeChild(backBtn)
				childNav.style.opacity = 0;
				childNav.style.left = '200%';
			})
		}
	}
}

{
	if (window.innerWidth < 992) {
		var mobileChild = document.getElementsByClassName('menu-item-has-children')
		for (var i = 0, len = mobileChild.length | 0; i < len; i = i + 1 | 0) {
			let mobileChildLinks = mobileChild[i].getElementsByClassName('dropdown-toggle')
			for (var b = 0, len2 = mobileChildLinks.length | 0; b < len2; b = b + 1 | 0) {
				mobileChildLinks[b].addEventListener('click', mobileSubNav)
			}
		}
	}
}


(() => {
	if (window.innerWidth > 991) {
		let tagline = document.querySelector('.header .tagline'),
			header = document.querySelector('.header'),
			deskNavs = document.querySelectorAll('.desk-nav'); // Both desktop navs

		function handleTagline() {
			header.classList.toggle('darken_bg');
			tagline.classList.toggle('invisible');
		}

		deskNavs.forEach((nav) => {
			for (var i = 0; i < nav.children.length; i++) {
				nav.children[i].addEventListener('mouseenter', handleTagline)
				nav.children[i].addEventListener('mouseleave', handleTagline)
			}
		})
	}
})();