{
	const accordion = document.querySelectorAll('.accordion_row')
	accordion.forEach(function(el) {
		let title = el.querySelector('.accordion_title')
		let show = el.querySelector('.accordion_reveal')
		show.classList.add('js-active')
		title.addEventListener('click', function(el) {
			title.classList.toggle('active')
			if(show.style.maxHeight) {
				show.style.maxHeight = null
			} else {
				show.style.maxHeight = show.scrollHeight + 'px'
			}
		})
	})
}